<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">

    <BusinessEventsTable />

  </div>
</template>

<script>

import BusinessEventsTable from '@/views/components/qosoor-components/BusinessEvents/BusinessEventsTable.vue'

export default {
  components: {
    BusinessEventsTable,
  },
}

</script>

<style lang="scss">

</style>
